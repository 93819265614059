import { createAsyncThunk } from '@reduxjs/toolkit';

import LeadAPI from '@/api/lead-event';
import { showErrorAlert, showSuccessAlert } from 'redux/app/app.actions';

// Constants for action types
export const FETCH_EVENTS = 'events/fetchEvents';
export const CREATE_LEAD_EVENT = 'events/createLeadEvent';
export const DELETE_LEAD_EVENT = 'events/deleteLeadEvent';

// Async Thunk to fetch events
export const fetchEvents = createAsyncThunk(FETCH_EVENTS, async (_, { rejectWithValue, dispatch }) => {
  return LeadAPI.getAll()
    .then((res) => {
      dispatch(showSuccessAlert('Список лидов успешно загружен!'));
      return res.data.leads; // Assuming `leads` is the response payload
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка загрузки данных');
    });
});

// Async Thunk to create lead event
export const createLeadEvent = createAsyncThunk(CREATE_LEAD_EVENT, async (eventData: any, { rejectWithValue, dispatch }) => {
  return LeadAPI.create(eventData)
    .then((res) => {
      dispatch(showSuccessAlert('Лид успешно создан!'));
      return res.data.lead; // Assuming `lead` is the response payload
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка создания лида');
    });
});

export const deleteLeadEvent = createAsyncThunk(DELETE_LEAD_EVENT, async (id: string, { rejectWithValue, dispatch }) => {
  return LeadAPI.delete(id)
    .then((res) => {
      dispatch(showSuccessAlert('Лид успешно удален!'));
      return res.data.lead; // Assuming `lead` is the response payload
    })
    .catch((error) => {
      dispatch(showErrorAlert(error?.response?.data));
      return rejectWithValue(error?.response?.data?.message || 'Ошибка создания лида');
    });
});
