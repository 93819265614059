import { createSelector } from 'reselect';

import { IFetchingStatuses, IFilters, IStatuses, LoadingStatus, CheckBoxAutocompleteOptionItem } from '@/types/common';
import {
  APPLICANT,
  TRAINEE,
  MANAGER,
  SENIORMANAGER,
  TOPMANAGER,
  SALESHEADMANAGER,
  REGIONALDIRECTOR,
  GROUPOFREGIONDIRECTOR,
  DEVELOPMENTDIRECTORDEPUTY,
  DEVELOPMENTDIRECTOR,
  ADMIN,
  ACCOUNTANT,
  ATTRACTIONMANAGER,
  ATTRACTIONHEADMANAGER,
  HR,
} from '@/variables/management';
import { getOfficeListByRole } from 'redux/office/office.selectors';
import { roleSelector, profileSelector } from 'redux/profile/profile.selectors';
import { RootState } from 'redux/store';
import { IUser } from 'redux/user/user.types';

export const userListSelector = (state: RootState): IUser[] => state?.users?.data;
export const userRequestStatusSelector = (state: RootState): IStatuses => state?.users?.status;
export const totalUsersSelector = (state: RootState): number => state.users.totalUsers;
export const userFilterSelector = (state: RootState): IFilters => state.users.filters;

export const getUserListByRole = createSelector(
  userListSelector,
  roleSelector,
  profileSelector,
  getOfficeListByRole,
  (users, role, profile, offices): IUser[] => {
    const { office, _id: id } = profile;

    const selfResponse = [APPLICANT, TRAINEE, MANAGER, ATTRACTIONMANAGER, ATTRACTIONHEADMANAGER].includes(role);
    const officeTraineeResponse = role == SENIORMANAGER;
    const officeManagerResponse = [TOPMANAGER].includes(role);
    const officeEmployeeResponse = [SALESHEADMANAGER, ACCOUNTANT, HR].includes(role);
    const regionResponse = role === REGIONALDIRECTOR;
    const regionGroupResponse = role === GROUPOFREGIONDIRECTOR;
    const allResponse = [ADMIN, DEVELOPMENTDIRECTOR, DEVELOPMENTDIRECTORDEPUTY].includes(role);

    if (selfResponse) {
      return users.filter((user) => user._id === id);
    } else if (officeTraineeResponse) {
      return users.filter((user) => (user.office === office && [APPLICANT, TRAINEE].includes(user.role)) || user._id === id);
    } else if (officeManagerResponse) {
      return users.filter(
        (user) =>
          (user.office === office &&
            [APPLICANT, TRAINEE, MANAGER, SENIORMANAGER, TOPMANAGER, SALESHEADMANAGER].includes(user.role)) ||
          user._id === id,
      );
    } else if (officeEmployeeResponse) {
      return users.filter((user) => user.office._id === office._id);
    } else if (regionResponse || regionGroupResponse) {
      const officeNames = offices.map((office) => office.name);

      return users.filter((user) => officeNames.includes(user.office.name));
    } else if (allResponse) {
      return users;
    }
    return [];
  },
);

export const getManagerByRole = createSelector(getUserListByRole, (users: IUser[]): CheckBoxAutocompleteOptionItem<string>[] => {
  return users
    .filter((user) => user.role === 'manager')
    .map((user) => ({
      value: user._id,
      title: `${user.personalData.name} ${user.personalData.surname}`,
      isActive: false,
      officeId: user.office._id,
    }));
});

export const getIsOfficeOfflineSelector = createSelector(profileSelector, (user): boolean => {
  return !!user.mobile && !user._id;
});

export const getIsOfficeOnlineSelector = createSelector(profileSelector, (user): boolean => {
  return user?.office?.isActive;
});

export const getUserListByOffice = createSelector(
  userListSelector,
  roleSelector,
  profileSelector,
  getOfficeListByRole,
  (users, role, profile, offices): IUser[] => {
    const { office, _id: id } = profile;

    const officeResponse = role === HR;
    const regionResponse = role === REGIONALDIRECTOR;
    const regionGroupResponse = role === GROUPOFREGIONDIRECTOR;
    const allResponse = [ADMIN, DEVELOPMENTDIRECTOR, DEVELOPMENTDIRECTORDEPUTY].includes(role);

    if (officeResponse) {
      return users.filter((user) => user.office === office);
    } else if (regionResponse || regionGroupResponse) {
      const officeNames = offices.map((office) => office.name);

      return users.filter((user) => officeNames.includes(user.office.name));
    } else if (allResponse) {
      return users;
    }

    return users.filter(
      (user) =>
        (user.office === office &&
          [APPLICANT, TRAINEE, MANAGER, SENIORMANAGER, TOPMANAGER, SALESHEADMANAGER].includes(user.role)) ||
        user._id === id,
    );
  },
);

export const currentUserSelector = (state: RootState): IUser | null => state.users.currentUser;

export const allowChangeEventDeadlineSelector = createSelector(roleSelector, (role): boolean => {
  return ![APPLICANT, TRAINEE, MANAGER, SENIORMANAGER, TOPMANAGER].includes(role);
});

export const usersStatusSelector = createSelector(
  userRequestStatusSelector,
  (status): LoadingStatus => ({
    isLoading: status === IFetchingStatuses.pending,
    isSuccess: status === IFetchingStatuses.success,
    isError: status === IFetchingStatuses.error,
  }),
);
