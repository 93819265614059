import React, { useEffect, useState, ReactNode, ReactElement } from 'react';

import 'regenerator-runtime/runtime';
import { AppBar, Toolbar, Box, Grid, Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useFirstTimeEntry } from '@/hooks';
import { LAST_LOGIN_DATE } from '@/utils/constants';
import { useWebsocket } from '@/utils/hooks';
import { startOfDay } from '@/utils/utils';
import HeaderAvatar from 'components/common/HeaderAvatar';
import QuickCreation from 'components/common/QuickCreation/QuickCreation';
import { SupportButton } from 'components/common/SupportButton';
import UserNotifications from 'components/common/UserNotifications';
import InvestingView from 'components/InvestingView';
import { getRolesData } from 'redux/app/app.actions';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { creationAccessByRole, viewingAccessByRole } from 'redux/managment/management.selectors';
import { getLastMessage } from 'redux/message/message.actions';
import { getMessageTextSelector } from 'redux/message/message.selectors';
import { getNotifications } from 'redux/notifications/notifications.actions';
import { officeFilterSelector } from 'redux/office/office.selectors';
import { useGetAllOfficesQuery } from 'redux/office/office.service';
import { getProfileData } from 'redux/profile/profile.actions';
import { myIdSelector, myProfileFullName } from 'redux/profile/profile.selectors';
import { getUsers } from 'redux/user/user.actions';
import {
  currentUserSelector,
  getIsOfficeOfflineSelector,
  getIsOfficeOnlineSelector,
  getManagerByRole,
  getUserListByRole,
} from 'redux/user/user.selectors';

import { mainLayoutStyles } from './layout.styles';
import Sidebar from '../components/common/Sidebar';

const ActionModal: React.ComponentType<{}> = dynamic(() => import('components/common/Modals/ActionModal'));

interface MainLayoutProps {
  children: ReactNode | ReactElement;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  useWebsocket();
  const { createClient, createOffice, createUser, createEvent, createMessage } = useAppSelector(creationAccessByRole);
  const { isAllowAccessToUsersProfile, highAccess, globalAccess, isAllowToBrokerClients } = useAppSelector(viewingAccessByRole);
  const isOfficeOffline = useAppSelector(getIsOfficeOfflineSelector);
  const isOfficeOnline = useAppSelector(getIsOfficeOnlineSelector);
  const officeFilters = useAppSelector(officeFilterSelector);
  const myId = useAppSelector(myIdSelector);
  const userFullName = useAppSelector(myProfileFullName);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const isFirstEntry = useFirstTimeEntry();
  const greetingMessage = useAppSelector(getMessageTextSelector);
  const classes = mainLayoutStyles();
  const hiddenHeaderElements = !isAllowAccessToUsersProfile && !highAccess;
  useGetAllOfficesQuery(officeFilters, { refetchOnMountOrArgChange: true });
  const currentEmployee = useAppSelector(currentUserSelector);
  const manager = useAppSelector(getUserListByRole);

  useEffect(() => {
    dispatch(getProfileData());
    dispatch(getRolesData()); // todo: условие доступа к ролям
    dispatch(getUsers(''));
    dispatch(getNotifications({}));
  }, [dispatch]);

  useEffect(() => {
    const loginDate = localStorage.getItem(LAST_LOGIN_DATE);
    const date = startOfDay(loginDate).toISOString();
    isFirstEntry && dispatch(getLastMessage({ date }));
  }, [dispatch, isFirstEntry]);

  useEffect(() => {
    handleDrawerClose();
  }, [router.pathname]);

  useEffect(() => {
    if (myId) {
      Sentry.setUser({ userId: myId, name: userFullName });
    }
  }, [myId]);

  const handleToggleSidebar = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (isOfficeOffline) {
    return <InvestingView />;
  }

  if (!isOfficeOffline && !isOfficeOnline) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar style={{ width: '100%' }}>
          <IconButton
            color={'default'}
            aria-label="open drawer"
            onClick={handleToggleSidebar}
            edge="start"
            className={classes.menuButton}
          >
            <Image src="/burger.svg" width={25} height={25} layout={'fixed'} />
          </IconButton>
          <Box className={classes.nav} component="div">
            <Tooltip title={'Рейтинги сотрудников'}>
              <IconButton size={'medium'} color={'primary'}>
                <Link href={'/ratings'}>
                  <EqualizerRoundedIcon />
                </Link>
              </IconButton>
            </Tooltip>

            {manager[0]?.role === 'manager' && (
              <Button variant="outlined" color="secondary" size={'small'} onClick={() => router.push('/leads')}>
                Все лиды <AttachMoneyIcon />
              </Button>
            )}

            {isAllowAccessToUsersProfile && (
              <Link href={'/users'} passHref>
                <Button
                  startIcon={<Image src="/profile.svg" width={18} height={18} layout={'fixed'} />}
                  size={'medium'}
                  variant={'text'}
                  color={'default'}
                >
                  <Typography className={classes.buttonText}>Сотрудники</Typography>
                </Button>
              </Link>
            )}

            {isAllowToBrokerClients && (
              <Link href={'/brokerClients'} passHref>
                <Button
                  startIcon={<Image src="/dollar.svg" width={18} height={18} layout={'fixed'} />}
                  size={'medium'}
                  variant={'text'}
                  color={'default'}
                >
                  <Typography className={classes.buttonText}>Торгующие клиенты</Typography>
                </Button>
              </Link>
            )}

            {highAccess && (
              <Link href={'/offices'} passHref>
                <Button
                  startIcon={<Image src="/offices.svg" width={18} height={18} layout={'fixed'} />}
                  size={'medium'}
                  variant={'text'}
                  color={'default'}
                >
                  <Typography className={classes.buttonText}>Офисы</Typography>
                </Button>
              </Link>
            )}

            {(createClient || createOffice || createUser || createEvent) && (
              <QuickCreation
                hiddenElements={hiddenHeaderElements}
                isAllowedCreateUser={createUser}
                isAllowedCreateClient={createClient}
                isAllowedCreateOffice={createOffice}
                isAllowedCreateMessage={createMessage}
              />
            )}
            <UserNotifications />
            <HeaderAvatar />
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={open} highAccess={highAccess} globalAccess={globalAccess} />

      <Box component="main" className={classes.content} position={'relative'}>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Box>

      <ActionModal />
      <SupportButton />
    </div>
  );
};

export default React.memo(MainLayout);
