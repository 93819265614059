import { createSlice } from '@reduxjs/toolkit';

import { createLeadEvent, fetchEvents } from './event.action';

export interface LeadState {
  _id: string;
  name: string;
  telephone: string;
  mail: string;
  tag?: string;
  url?: string;
  question1?: string;
  question2?: string;
  isFromGoogleSheet: boolean;
}

interface EventState {
  events: LeadState[];
  loading: boolean;
  error: string | null;
}

const initialState: EventState = {
  events: [],
  loading: false,
  error: null,
};

const leadEventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create Lead Event
      .addCase(createLeadEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLeadEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events.push(action.payload);
      })
      .addCase(createLeadEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default leadEventSlice.reducer;
