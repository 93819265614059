import axios from 'api';
import { LeadDto } from 'redux/lead-events/event.type';

const url = '/api/leads';

const LeadAPI = {
  getAll: () => axios.get(url),
  create: (data: LeadDto) => axios.post(url, data),
  delete: (id: string) => axios.delete(`${url}/${id}`),
};

export default LeadAPI;
